import React from "react";
import ContentContainer from "../../components/container/ContentContainer";
import ContactExpert from "../../components/controls/ContactExpert";
import Header from "../../components/sections/Header";
import HeadData from "../../data/HeadData";
import TitleDiv from "../../components/controls/TitleDiv";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout/Layout";

const ProductsPage = () => {
  const path = ["Products"];

  const products = [
    {
      title: "Hardwood Lumber",
      image: (
        <StaticImage
          src="../../images/hardwood-lumber-product.png"
          alt="Hardwood Lumber"
          placeholder="blurred"
          quality={100}
          className="h-60"
        />
      ),
      link: "./hardwood-lumber",
    },
    {
      title: "Hardwood Plywood",
      image: (
        <StaticImage
          src="../../images/hardwood-plywood-product.png"
          alt="Hardwood Plywood"
          placeholder="blurred"
          quality={100}
          className="h-60"
        />
      ),
      link: "./hardwood-plywood",
    },
    {
      title: "Lacquered Panels",
      image: (
        <StaticImage
          src="../../images/oneskin-related-section.png"
          alt="Lacquered Panels"
          placeholder="blurred"
          quality={100}
          className="h-60"
        />
      ),
      link: "./lacquered-boards",
    },
    {
      title: "Thermofused Laminates",
      image: (
        <StaticImage
          src="../../images/emporio-skin-section.png"
          alt="Thermofused Laminates"
          placeholder="blurred"
          quality={100}
          className="h-60"
        />
      ),
      link: "./thermofused-laminates",
    },
    {
      title: "Melamine",
      image: (
        <StaticImage
          src="../../images/melamine-product.png"
          alt="Melamine"
          placeholder="blurred"
          quality={100}
          className="h-60"
        />
      ),
      link: "./melamine-panels",
    },
    {
      title: "MDF",
      image: (
        <StaticImage
          src="../../images/mdf-product.png"
          alt="MDF"
          placeholder="blurred"
          quality={100}
          className="h-60"
        />
      ),
      link: "./mdf",
    },
    {
      title: "Pre-finished Wood Panels",
      image: (
        <StaticImage
          src="../../images/ridgemont-panels-section-1.png"
          alt="MDF"
          placeholder="blurred"
          quality={100}
          className="h-60"
        />
      ),
      link: "./pre-finished-wood-panels",
    }
  ];

  const ProductGallery = ({ title, image, link }) => {
    return (
      <Link to={link ? link : "."}>
        <div className="flex flex-col text-center text-white text-xl">
          <div className="bg-actionBlue py-3">{title}</div>
          {image}
        </div>
      </Link>
    );
  };
  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData title="Products" />
        <Header />

        <TitleDiv title="Products" className="mb-1" path={path} />

        <ContentContainer
          className="w-full max-w-screen-lg mt-10 lg:mt-28 bg-center grid grid-cols-1 md:grid-cols-2 gap-9 mb-10"
          opacityClassName="bg-opacity-60"
        >
          {products.map((product, index) => {
            return (
              <ProductGallery
                key={`productGallery${index}`}
                title={product.title}
                image={product.image}
                link={product.link}
              />
            );
          })}
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default ProductsPage;
